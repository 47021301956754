<template>
  <div>
    <head-layout  :head-title="$route.meta.title"
                  :head-btn-options="headBtnOptions"
                  @head-save="headSave('save')"
                  @head-save-back="headSave('back')"
                  @head-cancel="headCancel"
                  ></head-layout>
    <div class="newZtFormBox">
      <el-form :inline="false" :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.injection.field.tablename')" prop="tablename">
              <el-input class = 'new-form-input' :placeholder="$t('cip.dc.injection.field.aggregationCode')" v-model="form.tablename" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.injection.field.keycolumn')" prop="keycolumn">
              <el-input   type="textarea" class = 'new-form-input' :placeholder="$t('cip.dc.injection.field.groupingField')" v-model="form.keycolumn" size="mini" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import {injectionUp} from "@/api/dataAcquisition";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        tablename: [
          { required: true, message: this.$t('injection.form.aggregationCode'), trigger: 'change' }
        ],
        keycolumn: [
          { required: true, message: this.$t('injection.form.groupingField'), trigger: 'change' }
        ],
      },
      form: {
        aggCode: "",
        sourceField: "",
        targetField: "",
        targetTable: "",
        aggFlag: "",
      },
      formLabelWidth: '120px',
    }
  },
  created(){
    if(this.$route.query.id){
      this.form = JSON.parse(localStorage.getItem('injectEditObj'));
      this.$route.meta.title = this.$t('cip.dc.injection.msg.configurationEditing')
    }else{
      this.$route.meta.title = this.$t('cip.dc.injection.msg.configurationAddition')
    }
  },
  methods:{
    headSave(type){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            injectionUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.injection.msg.ModifiedSuccessfully'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            injectionUp(this.form).then(()=>{
              this.$message.success(this.$t('cip.dc.injection.msg.SuccessfullyAdded'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped>

</style>
